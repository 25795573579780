import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import "./SignIn.css";

function SignInComponent({auth, config}) {
    return (
        <div>
            <div className="container items-center px-5 py-6 lg:px-20 font-sans text-blueGray-700 text-left">
                <div className="flex flex-col w-full max-w-md p-3 mx-auto my-2 
                    transition duration-500 ease-in-out transform bg-white rounded-lg md:mt-0">
                    <div className="mt-4">
                        <div className='mt-3'>
                            <form action='' method='POST' className='space-y-5' onSubmit={() => {return false}}>
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-neutral-600">
                                        Email address
                                    </label>
                                    <div className="mt-1">
                                        <input id="email" name="email" type="email" autoComplete="email" required="" placeholder="Your Email" className="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300" />
                                    </div>
                                </div>
                                <div className='space-y-1'>
                                    <label htmlFor='password' className='block text-sm font-medium text-neutral-600'>
                                        Password
                                    </label>
                                    <div className='mt-1'>
                                        <input id='password' name='password' type='password' autoComplete="current-password" required="" placeholder="Your Password" className="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300" />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input id="remember-me" name="remember-me" type="checkbox" placeholder="Your password" className="w-4 h-4 text-blue-600 border-gray-200 rounded focus:ring-blue-500" />
                                        <label htmlFor="remember-me" className="block ml-2 text-sm text-neutral-600"> Remember me </label>
                                    </div>
                                    <div className="text-sm">
                                        {/* TODO: FIX LINK */}
                                        <a href="/" className="font-medium text-blue-600 hover:text-blue-500">
                                            Forgot your password?
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <button type='submit' className='flex items-center justify-center w-full px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>Sign In</button>
                                </div>
                            </form>

                            <div className='relative my-4'>
                                <div className='absolute inset-0 flex items-center'>
                                    <div className='w-full border-t border-gray-300'></div>
                                </div>
                                <div className='relative flex justify-center text-sm'>
                                    <span className='px-2 text-neutral-600 bg-white'>Or continue with</span>
                                </div>
                            </div>
                            
                            <StyledFirebaseAuth uiConfig={config} firebaseAuth={auth} />

                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default SignInComponent;