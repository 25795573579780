import FirebaseDataProvider from "./FirebaseDataProvider";

class DatabaseService {
    static provider = new FirebaseDataProvider();

    // static {
    //     //Initialisation goes here
    // }

    static async saveIndoorWorkout(workout) {
        console.info(workout);
        return await this.provider.saveIndoorWorkout(workout);
    }
}

export default DatabaseService;