import { useState } from 'react'
import { authenticator } from "../../FirebaseAuthentication";
import { useAuthState } from "react-firebase-hooks/auth";
import Modal from "./../../components/modal/Modal";
import { PlusCircleIcon } from '@heroicons/react/solid'
import { SaveIndoorWorkout, saveWorkout } from "./../../components/save-indoor-workout/SaveIndoorWorkout";
import OutdoorRowerIcon from "./../../assets/lime-canoist.png";
import IndoorRowerIcon from "./../../assets/clip-1477.png";


// TODO: Recieve the user rather than from `authenticator`
function DashboardPage() {
    const [user] = useAuthState(authenticator);
    // console.info(user);
    const [shouldShowModal, showModal] = useState(false);

    return (
        <div className='dashboard'>
            <Modal toggleModal={showModal} open={shouldShowModal}>
                <SaveIndoorWorkout onCancel={() => showModal(false)} onSave={(...args) => {
                        saveWorkout(...args, user);
                        showModal(false);
                    }} />
            </Modal>

            <div className='importers md:grid md:grid-cols-2 gap-4 md:mt-52'>
                <div className='place-items-center border-r-2'>
                    <img src={IndoorRowerIcon} className='h-56 mx-auto' alt='rower icon'/>
                    <button className="ox-btn-primary bg-amber-500 dark:bg-amber-600 my-8" 
                        onClick={() => showModal(true)}>
                        Log Indoor Workout
                        <PlusCircleIcon className='h-4 w-4 mx-1'/>
                    </button>
                </div>
                <div>
                    <img src={OutdoorRowerIcon} className='h-56 mx-auto' alt='outdoor rower icon' />
                    <button className="ox-btn-primary my-8 dark:bg-green-500" 
                        onClick={() => console.info('Import GPX file clicked')}>
                        Import GPX file
                        <PlusCircleIcon className='h-4 w-4 mx-1'/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DashboardPage;