import DatabaseService from "./DatabaseService";

class Workout {
    docID = "";

    strokeRate = 0;
    split = 0;
    duration = 0;
    totalDistance = 0;

    userID = ""

    workoutDate = new Date()
}

class IndoorWorkout extends Workout {
    constructor(strokeRate, distance, split, duration) {
        super();
        this.strokeRate = strokeRate;
        this.totalDistance = distance;
        this.split = split;
        this.duration = duration;
    }

    async saveFor(userID) {
        console.info("Saving indoor workout for ", userID);
        this.userID = userID;
        const docID = await DatabaseService.saveIndoorWorkout(this);
        this.docID = docID;
    }
}

class OutdoorWorkout extends Workout { }

export { IndoorWorkout, OutdoorWorkout };