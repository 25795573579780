import React, { useEffect } from 'react';
import AuthenticationPage from './pages/auth/Authentication';
import DashboardPage from './pages/dashboard/DashboardPage';
import Navbar from './components/navbar/Navbar';
import { Routes, Route, useNavigate, Outlet } from "react-router-dom";
import { authenticator } from "./FirebaseAuthentication";
import { useAuthState } from "react-firebase-hooks/auth";

import './App.css';

function Layout () {
    const navigate = useNavigate();
    // const [user, loading, error] = useAuthState(authenticator);
    const [user, ,] = useAuthState(authenticator);
    useEffect(() => {
        if(!user){
            navigate('/login');
        }
    }, [user, navigate]);

    if(!user)
        return null;

    console.info(user);

    return (
        <div>
            <Outlet />
        </div>
    );
}



function App() {
    return (
        <div className="App dark:text-white">
            <Navbar />

            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<DashboardPage />}/>
                </Route>
                <Route path="/login" element={<AuthenticationPage />} />
            </Routes>
        </div>
    );
}

export default App;
