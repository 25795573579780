import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { authenticator } from "./../../FirebaseAuthentication";
import oxunLogo from "./../../assets/oxun-logo.png";

function Navbar () {
    // const [user, loading, error] = useAuthState(authenticator);
    const [user, ,] = useAuthState(authenticator);
    const [userPhotoElem, setUserPhotoElem] = useState(<span></span>);
    // const [logoutButton, setLogoutButton] = useState(<span></span>);
    useEffect(() => {
        const userPhoto = user ? <img src={user.photoURL} alt='' className='rounded-full'/> : <span></span>
        setUserPhotoElem(userPhoto)
    }, [user]);
    return (
        <header className="text-gray-600 body-font border-b-2">
            <div className="container mx-auto flex flex-wrap p-2 flex-col md:flex-row justify-between">
                <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0" href='/'>
                    <img src={oxunLogo} width="25px" alt="Oxun logo" />
                    <span className="ml-3 text-xl dark:text-white">Oxun Rowing</span>
                </a>
                <div className='flex'>
                    <div className='avatar'>
                        <div className='rounded-full w-10 h-10'>
                            {userPhotoElem}
                        </div>
                    </div>
                    <div>
                        {user &&
                        <button className="ox-btn-primary bg-white" onClick={() => authenticator.signOut()}>Logout</button>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Navbar;