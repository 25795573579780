import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, doc, setDoc, connectFirestoreEmulator,Timestamp } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// let firebaseApp;
let analytics;

// Initialize Firebase
// if(process.env.NODE_ENV == 'production'){
//     firebaseApp = initializeApp(firebaseConfig);
// }else{
//     firebaseApp = initializeTestApp(firebaseConfig);
// }
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();
const authenticator = getAuth(firebaseApp);

if (process.env.NODE_ENV !== 'test') {
    analytics = getAnalytics(firebaseApp);
}

if (process.env.NODE_ENV !== 'production') {
    // analytics.disable();
    // console.info(analytics);
}

if (process.env.NODE_ENV !== 'production') {
    // Commented out for now as it causes this bug https://www.notion.so/Permission-issues-with-firebase-local-emulator-802c6647360d4e0b90c974121f6ce110
    connectAuthEmulator(authenticator, "http://127.0.0.1:9099");
    connectFirestoreEmulator(db, "127.0.0.1", 8080);
}



// TODO: I should probably move this to a firebase cloud function instead
// more info on cloud functions here https://firebase.google.com/docs/functions/write-firebase-functions
async function saveNewUser(authResult) {
    const user = authResult.user;
    const email = user.email;
    const uid = user.uid;
    console.info(authResult);
    console.info(`Attempting to save new user ${email}`);
    try {
        const newUserDocRef = doc(db, "users", uid);
        const savedDocRef = await setDoc(newUserDocRef, {
            email: email,
            uuid: uid,
            joinDate: Timestamp.fromDate(new Date())
        });
        console.log("[saveNewUser]: Successfully saved new user with ID: ", savedDocRef);
    } catch (e) {
        //TODO: Handle this properly, might need to redirect users back to login page or help section
        console.error("Error saving user: ", e);
    }
}

export {
    authenticator,
    firebaseApp,
    analytics,
    saveNewUser,
    db
};