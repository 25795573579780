// Copyright 2022, Ara Intelligence.

import React, { useEffect, useState } from 'react';

/**
 *
 * TimeDuration
 * 
 * Show the hours, minutes and seconds input elements for capturing
 * time duration.
 * The maximum hour that can be recieved is equal to `Number.MAX_VALUE`
 * 
 * > NB:`props.hideHours` is true, then it shows only minutes and seconds
 * > It does not limit the maximum value for minutes when set
 *
 * @param props.hideHours Used to show or hide the hours component
 * @param props.onDurationChange Listener for receiving the parsed time duration
 */
function TimeDuration(props) {
    const shouldHideHours = props.hideHours ? props.hideHours : false;
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const onChangeFcn = props.onDurationChange;

    useEffect(() => {
        if (onChangeFcn) {
            onChangeFcn(hours, minutes, seconds);
        }
    }, [hours, minutes, seconds, onChangeFcn]);

    function updateHours(ev) {
        const value = parseInt(ev.target.value);
        if (value < 0){
            ev.preventDefault();
            ev.returnValue = false; // For IE and older browsers
            return;
        }

        setHours(isNaN(value) ? 0 : value);
    }

    // Update `minutes`. Ensures its value does not exceed 60 when user is also
    // recording number of hours.
    function updateMinutes(ev) {
        const value = parseInt(ev.target.value);
        const shouldShowHours = !shouldHideHours;
        // only check for above 60 when the hours component is visible
        const checkExceeds60 = (shouldShowHours && (value > 60))

        if (checkExceeds60 || value < 0){
            ev.preventDefault();
            ev.returnValue = false; // For IE and older browsers
            return;
        }

        setMinutes(isNaN(value) ? 0 : value);
    }

    // Ensures the number of seconds does not exceed 60
    function updateSeconds(ev) {
        const value = parseInt(ev.target.value);
        if (value > 60 || value < 0) {
            ev.preventDefault();
            ev.returnValue = false;
            return;
        }

        setSeconds(isNaN(value) ? 0 : value);
    }

    const inputCSSClasses = "dark:text-black w-16 font-mono appearance-none text-center text-neutral-600 transition duration-500 ease-in-out focus:outline-none border border-transparent rounded-sm focus:ring-1";
    const labelCSSClasses = "font-medium uppercase dark:text-slate-400 text-[11px]";

    return (
        <div className='text-center'>
            <div className=''>
                <div className='w-full flex flex-row flex-auto flex-wrap flex-1 gap-1 justify-center text-sm'>
                    {/* Hours component */}
                    {!shouldHideHours && <div className='flex flex-col shrink'>
                        <label htmlFor="hours-input" data-testid='hours-input' className={labelCSSClasses}>Hours</label>
                        <input 
                            className={inputCSSClasses} id='hours-input' type='number'
                            value={hours} onChange={(ev) => updateHours(ev)} />
                    </div>}

                    {/* Minutes component */}
                    <div className='flex flex-col shrink'>
                        <label htmlFor="minutes-input" data-testid='minutes' className={labelCSSClasses}>Minutes</label>
                        <input 
                            className={inputCSSClasses} id='minutes-input' type='number' data-testid='minutes-input'
                            value={minutes} onChange={(ev) => updateMinutes(ev)} />
                    </div>

                    {/* Seconds component */}
                    <div className='flex flex-col shrink'>
                        <label htmlFor="seconds-input" data-testid='seconds' className={labelCSSClasses}>Seconds</label>
                        <input 
                            className={inputCSSClasses} id='seconds-input' type='number' data-testid="seconds-input"
                            value={seconds} onChange={(ev) => updateSeconds(ev)} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function toSeconds(hours=0, minutes=0, seconds=0) {
    return (hours * 60 * 60) + (minutes * 60) + seconds; 
}

export { TimeDuration, toSeconds };