import React, {useEffect} from "react";
import SignInComponent from "../../components/signin/SignIn";
import firebase from 'firebase/compat/app';
import { authenticator, saveNewUser } from "../../FirebaseAuthentication";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import 'firebase/compat/auth';
import './Authentication.css';

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: { //https://github.com/firebase/firebaseui-web#available-callbacks
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            console.info("Signed in successfully");
            // const user = authResult.user;
            // const credential = authResult.credential;
            const isNewUser = authResult.additionalUserInfo.isNewUser;
            // const providerId = authResult.additionalUserInfo.providerId;
            // const operationType = authResult.operationType;
            console.info(isNewUser);
            if (isNewUser) {
                saveNewUser(authResult);
            }
            return false;
        }
    }
};

function AuthenticationPage() {
    const navigate = useNavigate();
    // const [user, loading, error] = useAuthState(authenticator);
    const [user,,] = useAuthState(authenticator);
    useEffect(() => {
        if(user){
            navigate('/',{ replace: true });
        }
    }, [user, navigate]);


    return (
        <div className="authentication">
            <SignInComponent auth={authenticator} config={uiConfig} />
        </div>
    );
}

export default AuthenticationPage;