import { collection, addDoc, getDocs, Timestamp } from "firebase/firestore";
import { db } from "./../FirebaseAuthentication";

const INDOOR_WORKOUT_TABLE = "indoor_workouts";


const indoorWorkoutConverter = {
    toFirestore: (indoorWorkout) => {
        const workoutDateTime = indoorWorkout.workoutDate;
        indoorWorkout = JSON.parse(JSON.stringify(indoorWorkout));
        indoorWorkout.workoutDate = Timestamp.fromDate(workoutDateTime);
        return indoorWorkout;
    },
    fromFirestore: (snapshot, options) => {
        // const data = snapshot.data(options);
        // UNIMPLEMENTED!
        return Error("Unimplemented method `fromFirestore`");
    }
};


//TODO: Figure out how to make `DatabaseProvider` an interface
class DatabaseProvider {}

class FirebaseDataProvider extends DatabaseProvider {

    async collectionExists(collectionName) {
        const querySnapshot = await getDocs(collection(db, collectionName));
        return !querySnapshot.empty;
    }

    async saveIndoorWorkout(indoorWorkout) {
        const workout = indoorWorkoutConverter.toFirestore(indoorWorkout);
        const userID = workout.userID;
        console.info(workout);
        try {
            let userWorkoutCollectionRef = collection(db, INDOOR_WORKOUT_TABLE, userID, "sessions");
            
            const workoutRef = await addDoc(userWorkoutCollectionRef, workout);
            console.log(`Workout saved with ref: ${workoutRef}`);
            return workoutRef.id;
        }catch(e) {
            console.error("Error adding indoor workout", e);
        }
    }
}

export default FirebaseDataProvider;