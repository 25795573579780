import { useState } from 'react'
import { IndoorWorkout } from "./../../models/Workout";
import { TimeDuration, toSeconds } from "./../time-duration/TimeDuration"
// import { ExclamationIcon } from '@heroicons/react/outline';

function SaveIndoorWorkout(props) {
    const [strokeRate, setStrokeRate] = useState(0);
    const [split, setSplit] = useState(0); // represented in seconds
    const [duration, setDuration] = useState(0); // represented in seconds
    const [totalDistance, setTotalDistance] = useState(0);

    const inputCSSClasses = "font-mono w-2/3 px-5 py-3 text-center text-lg text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300";

    return (
        <div className='indoor-workout m-10'>
            <div className='grid grid-rows-3 grid-flow-col gap-4 dark:text-blue-700'>
                <div className='grid grid-cols-2 gap-4 text-center content-evenly'>
                    <div>
                        <p className=" text-sm font-medium text-neutral-600">
                            Stroke Rate
                        </p>
                        <div className="mt-1">
                            <input type="number" value={strokeRate} onChange={(ev) => setStrokeRate(ev.target.value)} className={inputCSSClasses} data-testid="input-stroke-rate"/>
                        </div>
                    </div>
                    <div>
                        <p className=" text-sm font-medium text-neutral-600">
                            Split (per 500m)
                        </p>
                        <div className="mt-1" data-testid="input-split" id='split'>
                            {/* <input type="text" value={split} onChange={(ev) => setSplit(ev.target.value)} className={inputCSSClasses} data-testid="input-split" /> */}
                            <TimeDuration 
                                hideHours={true}  
                                onDurationChange={(h,m,s) => setSplit(toSeconds(h,m,s))} />
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-4 text-center content-evenly'>
                    <div>
                        <p className=" text-sm font-medium text-neutral-600">
                            Duration
                        </p>
                        <div className="mt-1" data-testid="input-duration" id='duration'>
                            <TimeDuration onDurationChange={(h,m,s) => setDuration(toSeconds(h,m,s))} />
                        </div>
                    </div>
                    <div>
                        <p className=" text-sm font-medium text-neutral-600">
                            Total Distance (m)
                        </p>
                        <div className="mt-1">
                            <input 
                                type="number" value={totalDistance} onChange={(ev) => setTotalDistance(ev.target.value)}
                                className={inputCSSClasses} data-testid="input-total-distance" />
                        </div>
                    </div>
                </div>

                {/* save and cancel buttons */}
                <div className='grid grid-cols-2 gap-4 text-center place-items-center'>
                    <button className='ox-btn-primary w-4/5' onClick={() => props.onCancel?.()}>Cancel</button>
                    <button 
                        className='ox-btn-primary w-4/5 bg-green-500 hover:bg-green-700' 
                        onClick={() => props.onSave?.(strokeRate, split, duration, totalDistance)}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

async function saveWorkout(strokeRate, split, duration, totalDistance, user) {
    const workout = new IndoorWorkout(strokeRate, totalDistance, split, duration);
    await workout.saveFor(user.uid);
}

export { SaveIndoorWorkout, saveWorkout };